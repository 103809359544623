import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Table, Button } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
import { FaTimes, FaCheck } from "react-icons/fa";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import Meta from "../../components/Meta";
import PaginateAdminOrderList from "../../components/PaginateAdminOrderList";
import SearchBoxOrderlistAdmin from "../../components/SearchBoxOrderlistAdmin";
import { useGetOrdersQuery } from "../../slices/ordersApiSlice";

const OrderListScreen = () => {
  const { keyword, pageNumber } = useParams();
  const { data, isLoading, error } = useGetOrdersQuery({ keyword, pageNumber });

  return (
    <>
      <Meta
        title="Order List | Admin | Lugo Parts & Restoration"
        description="Admin panel for managing orders at Lugo Parts & Restoration."
      >
        <meta name="robots" content="noindex, nofollow" />
      </Meta>

      <h1>Orders</h1>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <SearchBoxOrderlistAdmin />
          {keyword && (
            <Link to="/admin/orderlist" className="btn btn-light mt-4 mb-4">
              Go Back
            </Link>
          )}
          <Table striped hover responsive className="table-sm">
            <thead>
              <tr>
                <th>ORDER ID</th>
                <th>USER</th>
                <th>GUEST</th>
                <th>DATE</th>
                <th>TOTAL</th>
                <th>SHIPPING</th>
                <th>HANDLING</th>
                <th>STATE</th>
                <th>TAX</th>
                <th>COUPON</th>
                <th>PAID</th>
                <th>DELIVERED</th>
                <th>DETAILS</th>
              </tr>
            </thead>
            <tbody>
              {data.orders.map((order) => (
                <tr key={order._id}>
                  <td>{order._id}</td>
                  <td>{order.user ? order.user.name : order.guestEmail}</td>
                  <td>
                    {order.user ? (
                      <FaTimes style={{ color: "red" }} />
                    ) : (
                      <FaCheck style={{ color: "green" }} />
                    )}
                  </td>
                  <td>
                    {" "}
                    {new Date(order.createdAt)
                      .toLocaleString("en-US", {
                        timeZone: "America/Los_Angeles", // Pacific Time (PT)
                        hour12: false,
                      })
                      .substring(0, 19) + " (PT)"}
                  </td>
                  <td>${order.totalPrice}</td>
                  <td>${order.shippingPrice}</td>
                  <td>${order.handlingFee}</td>
                  <td>{order.shippingAddress.state}</td>
                  <td>${order.taxPrice}</td>
                  <td>
                    {order.couponCode ? (
                      <FaCheck style={{ color: "green" }} />
                    ) : (
                      <FaTimes style={{ color: "red" }} />
                    )}
                  </td>

                  <td>
                    {order.isPaid ? (
                      new Date(order.paidAt)
                        .toLocaleString("en-US", {
                          timeZone: "America/Los_Angeles", // Pacific Time (PT)
                          hour12: false,
                        })
                        .substring(0, 19) + " (PT)"
                    ) : (
                      <FaTimes style={{ color: "red" }} />
                    )}
                  </td>
                  <td>
                    {order.isDelivered ? (
                      new Date(order.deliveredAt)
                        .toLocaleString("en-US", {
                          timeZone: "America/Los_Angeles",
                          hour12: false,
                        })
                        .substring(0, 19) + " (PT)"
                    ) : (
                      <FaTimes style={{ color: "red" }} />
                    )}
                  </td>
                  <td>
                    <LinkContainer to={`/order/${order._id}`}>
                      <Button variant="light" className="btn-sm">
                        Details
                      </Button>
                    </LinkContainer>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <PaginateAdminOrderList
            pages={data.pages}
            page={data.page}
            keyword={keyword ? keyword : ""}
            isAdmin={true}
          />
        </>
      )}
    </>
  );
};

export default OrderListScreen;

import React from "react";
import { Carousel, Image } from "react-bootstrap";

const GalleryCarousel = () => {
  // Updated image list to include captions
  const imageList = [
    {
      imageName: "galleryOrangeNew.webp",
      caption: "1957 Chevrolet - Model 3100",
    },
    {
      imageName: "Gallery01New.webp",
      caption: "Meet Titan: Our Friendly Shop Dog",
    },
    { imageName: "Gallery02New.webp", caption: "1958 Chevrolet - Model 3124" },
    { imageName: "Gallery03New.webp", caption: "1955 GMC - Model 150" },
    { imageName: "Gallery04New.webp", caption: "1957 Chevrolet - Model 3100" },
    { imageName: "Gallery05New.webp", caption: "1957 Chevrolet - Model 3100" },
    { imageName: "Gallery06New.webp", caption: "1957 Chevrolet - Model 3100" },
    { imageName: "Gallery08New.webp", caption: "1959 Chevrolet - Model 3100" },
    { imageName: "Gallery07New.webp", caption: "Ground Breaking" },
  ];

  return (
    <Carousel pause="hover" className="bg-primary mb-4">
      {imageList.map((image, index) => (
        <Carousel.Item key={index} className="carousel-item-bg">
          <div className="d-flex justify-content-center">
            <Image
              src={`/images/carousel/webp/${image.imageName}`}
              alt={`Gallery Image ${index + 1}`}
              fluid
            />
          </div>
          {/* Displaying specific caption for each image */}
          <Carousel.Caption className="carousel-caption">
            <h2>{image.caption}</h2>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default GalleryCarousel;
